import React, { useEffect } from "react";
import useDarkMode from "../../helpers/useDarkMode";
import { ReactComponent as TheFrenzyLogoBlack } from "../../assets/svg/the-frenzy-logo-black.svg";
import { ReactComponent as TheFrenzyLogoWhite } from "../../assets/svg/the-frenzy-logo-white.svg";
import { ReactComponent as ShoppingCartIconBlack } from "../../assets/svg/shopping-cart-icon-black.svg";
import { ReactComponent as ShoppingCartIconWhite } from "../../assets/svg/shopping-cart-icon-white.svg";
import { ReactComponent as UserLoveIconBlack } from "../../assets/svg/user-love-icon-black.svg";
import { ReactComponent as UserLoveIconWhite } from "../../assets/svg/user-love-icon-white.svg";
import { ReactComponent as SearchIconBlack } from "../../assets/svg/search-icon-black.svg";
import { ReactComponent as SearchIconWhite } from "../../assets/svg/search-icon-white.svg";
import { ReactComponent as MenuBarIconBlack } from "../../assets/svg/menu-bar-icon-black.svg";
import { ReactComponent as MenuBarIconWhite } from "../../assets/svg/menu-bar-icon-white.svg";
import Buttons from "../buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";

type HeaderProps = {
	isUsers?: string;
};

const Header: React.FC<HeaderProps> = (props): JSX.Element => {
	const { isUsers } = props;
	const [theme, toggleTheme] = useDarkMode();
	const { width } = useWindowResize();

	useEffect(() => {
		const ac = new AbortController();

		// eslint-disable-next-line no-console
		console.log("Dashboard isUsers", isUsers);

		return function cleanup() {
			ac.abort();
		};
	}, []);

	return (
		<>
			<div className="sticky top-0 z-10 ">
				{width > 1060 ? (
					<div className="h-24 px-20 border-b flex items-center justify-evenly bg-white dark:bg-black border-gray-100 dark:border-slate-500">
						<div>
							{theme === "light" ? (
								<TheFrenzyLogoBlack title="The-Frenzy Logo" />
							) : (
								<TheFrenzyLogoWhite title="The-Frenzy Logo" />
							)}
						</div>
						<nav className="font-ProximaNovaSemiBold text-lg mx-24 text-black dark:text-white flex text-center justify-between w-3/5">
							<button type="button" title="Men" className="ml-0 mr-[10px]">
								Men
							</button>
							<button type="button" title="Women" className="mr-[10px]">
								Women
							</button>
							<button type="button" title="Kids" className="mr-[10px]">
								Kids
							</button>
							<button type="button" title="Sale" className="mr-[10px]">
								Sale
							</button>
							<button
								type="button"
								title="Gift Card"
								className="mr-[6px] whitespace-nowrap"
							>
								Gift Card
							</button>
						</nav>
						<div className="flex items-center justify-between">
							<div>
								<label
									className="block text-gray-700 text-sm mb-2 leading-5"
									htmlFor="search"
								>
									<div className="flex justify-end">
										<input
											id="search"
											// value={password}
											type="text"
											placeholder="Search"
											// onChange={(e) => handlePasswordHash(e.target.value)}
											className="mt-2 h-10 w-52 text-lg leading-5 pl-3 pr-9 border appearance-none focus:outline-none
												border-black dark:border-white
												bg-white dark:bg-black
												text-black dark:text-white"
										/>
										<button type="button" title="Search">
											{theme === "light" ? (
												<SearchIconBlack className="-ml-8 mt-2 mr-3 bg-white dark:bg-black " />
											) : (
												<SearchIconWhite className="-ml-8 mt-2 mr-3 bg-white dark:bg-black " />
											)}
										</button>
									</div>
								</label>
							</div>
						</div>
						<Buttons
							specificButtonTitle="Login"
							specificButtonStyling="px-4"
							specificButtonText="Login"
							specificButtonClick={() => null}
						>
							{theme === "light" ? (
								<UserLoveIconBlack className="ml-2" />
							) : (
								<UserLoveIconWhite className="ml-2" />
							)}
						</Buttons>
						<button
							type="button"
							title="Theme Toggle"
							className="flex items-center bottom-0 flex-auto ml-5"
						>
							<label htmlFor="themeToggle">
								<span
									className="w10 h10 bg-the-frenzy-orange rounded-full shadow-lg cursor-pointer text-white flex items-center justify-center"
									onClick={() => toggleTheme()}
								>
									{theme === "light" ? (
										// dark moon
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
										</svg>
									) : (
										// light bulb
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828
                    9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4
                    0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
											/>
										</svg>
									)}
								</span>
							</label>
						</button>
						<button title="Cart" className="ml-2">
							{theme === "light" ? (
								<ShoppingCartIconBlack />
							) : (
								<ShoppingCartIconWhite />
							)}
						</button>
					</div>
				) : (
					<>
						<div className="absolute flex justify-center items-center h-16 w-full">
							{theme === "light" ? (
								<TheFrenzyLogoBlack title="The-Frenzy Logo" className="h-10" />
							) : (
								<TheFrenzyLogoWhite title="The-Frenzy Logo" className="h-10" />
							)}
						</div>
						<div className="h-16 px-5 border-b flex items-center justify-between bg-white dark:bg-black border-gray-100 dark:border-slate-500">
							<button type="button">
								{theme === "light" ? (
									<MenuBarIconBlack />
								) : (
									<MenuBarIconWhite />
								)}
							</button>
							<div className="flex items-center justify-between">
								<button type="button">
									{theme === "light" ? (
										<SearchIconBlack title="Search" />
									) : (
										<SearchIconWhite title="Search" />
									)}
								</button>
								<button type="button" title="Cart" className="ml-3">
									{theme === "light" ? (
										<ShoppingCartIconBlack className="h-7 w-7" />
									) : (
										<ShoppingCartIconWhite className="h-7 w-7" />
									)}
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Header;

/* <div title="Promo Offer!" className="h-6 w-screen bg-black pl-2">
		<p className="scrollingText cursor-pointer text-sm text-center text-white font-ProximaNovaSemiBold">
			<span>
				Get the perfect Valentines Day gift and enjoy free shipping on all
				orders. See gift ideas for HER and for HIM.
			</span>
		</p>
	</div>
*/
