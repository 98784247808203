const AuthRoutes = {
	dashboard: "/dashboard",
};

const NonAuthRoutes = {
	landingPage: "/",
	login: "/login",
	signup: "/sign-up",
	completeSignup: "/complete-sign-up",
	verifyEmail: "/account/verify",
	forgotPassword: "/forgot-password",
	resetPassword: "/reset-password",
	pageNotFound: "/404/page-not-found",
};

export { AuthRoutes, NonAuthRoutes };
