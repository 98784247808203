import React from "react";
import Header from "../../components/header/header";

function LandingPage() {
	return (
		<div className="h-screen w-screen bg-white dark:bg-black">
			<Header />
			<p className="text-black dark:text-white text-center py-10">
				LandingPage
			</p>
		</div>
	);
}

export default LandingPage;
