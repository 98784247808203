import React from "react";

type ButtonProps = {
	specificButtonTitle?: string;
	specificButtonStyling?: string;
	specificButtonText?: string;
	specificButtonClick?: React.MouseEventHandler;
	children: React.ReactNode;
};

const Buttons: React.FC<ButtonProps> = (props): JSX.Element => {
	const {
		specificButtonTitle,
		specificButtonStyling,
		specificButtonText,
		specificButtonClick,
		children,
	} = props;

	return (
		<button
			type="button"
			title={specificButtonTitle}
			onClick={specificButtonClick}
			className={`${specificButtonStyling} mySVG-button w-28 h-10 px-2 ml-8 border-2 rounded-3xl flex items-center justify-evenly
		border-black dark:border-white
		hover:bg-black dark:hover:bg-white
		text-black dark:text-white hover:text-white dark:hover:text-black`}
		>
			<p className="font-ProximaNovaSemiBold text-lg">{specificButtonText}</p>
			{children}
		</button>
	);
};

export default Buttons;
